
/* eslint-disable */
import { languageTag } from "./runtime.js"
import * as en from "./messages/en.js"

/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_header_signup = (params = {}, options = {}) => {
	return {
		en: en.site_header_signup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_tagline = (params = {}, options = {}) => {
	return {
		en: en.site_index_tagline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_heading = (params = {}, options = {}) => {
	return {
		en: en.site_index_heading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_callToAction = (params = {}, options = {}) => {
	return {
		en: en.site_index_callToAction
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_callToActionShort = (params = {}, options = {}) => {
	return {
		en: en.site_index_callToActionShort
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_pricingTagline = (params = {}, options = {}) => {
	return {
		en: en.site_index_pricingTagline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_image = (params = {}, options = {}) => {
	return {
		en: en.site_index_image
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawControlsHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawControlsHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawControlsDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawControlsDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawEditingHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawEditingHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawEditingDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawEditingDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawImportHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawImportHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawImportDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawImportDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_designHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_designDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designStrokesHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_designStrokesHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designStrokesDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_designStrokesDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designTextHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_designTextHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designTextDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_designTextDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designTextImage = (params = {}, options = {}) => {
	return {
		en: en.site_index_designTextImage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designStrokesImage = (params = {}, options = {}) => {
	return {
		en: en.site_index_designStrokesImage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designBasemapImage = (params = {}, options = {}) => {
	return {
		en: en.site_index_designBasemapImage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designBasemapHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_designBasemapHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designBasemapDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_designBasemapDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawImage = (params = {}, options = {}) => {
	return {
		en: en.site_index_drawImage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareLinkHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareLinkHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareLinkDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareLinkDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareExportHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareExportHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareExportDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareExportDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareCloudHeading = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareCloudHeading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareCloudDescription = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareCloudDescription
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareImage = (params = {}, options = {}) => {
	return {
		en: en.site_index_shareImage
	}[options.languageTag ?? languageTag()]()
}


