
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_header_signup = () => `Sign up`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_tagline = () => `Draw, design, and share. Turbomaps makes it easy to create a stunning map in minutes.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_heading = () => `Create & share beautiful maps`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_callToAction = () => `Get started with Turbomaps`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_callToActionShort = () => `Get started`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_pricingTagline = () => `Free, while in beta`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_image = () => `Turbomaps UI`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawHeading = () => `Draw`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawDescription = () => `Draw without worrying about geometry. Turbomaps makes it easy to annotate maps & provides powerful editing tools.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawControlsHeading = () => `Intuitive controls`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawControlsDescription = () => `Draw like you would in an image editor. Move, scale, and rotate entire layers or edit individual points.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawEditingHeading = () => `Powerful editing`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawEditingDescription = () => `Select multiple layers to quickly edit in bulk. Easily fix mistakes with instant undo/redo.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawImportHeading = () => `Import your data`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawImportDescription = () => `Drop or paste in your existing GeoJSON data. Use the powerful editing tools, or work directly in the data editor.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designHeading = () => `Design`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designDescription = () => `Unleash your creativity with our design tools. Customize your maps to make your ideas come to life.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designStrokesHeading = () => `Effortlessly beautiful`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designStrokesDescription = () => `Maps look great out of the box. Colors, weights, and strokes can be customized to make your items stand out.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designTextHeading = () => `Annotate everything`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designTextDescription = () => `Add text anywhere on your map. Choose from a variety of styles to emphasize your content.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designTextImage = () => `Map with text shown with UI for text options`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designStrokesImage = () => `Rectangle on map with purple stroke and fill`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designBasemapImage = () => `Basemap options - light, dark, streets, outdoors, satellite`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designBasemapHeading = () => `Basemap options`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_designBasemapDescription = () => `Choose from a variety of beautiful basemaps. Satellite & outdoor maps are a single click away.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareHeading = () => `Share`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareDescription = () => `Share your creations and keep them in sync. Our interactive maps work great on all phones, tablets, and other devices.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_drawImage = () => `Drawing tools available in Turbomaps`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareLinkHeading = () => `Share a link`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareLinkDescription = () => `Get a shareable link to your map with just one click. Any changes you make will be reflected for others.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareExportHeading = () => `Export your data`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareExportDescription = () => `You own your data, and it can easily be exported as GeoJSON for use in other applications.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareCloudHeading = () => `Pick up where you left off`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareCloudDescription = () => `Turbomaps runs in your browser so you can work from any computer. Your data is securely stored in the cloud.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const site_index_shareImage = () => `Turbomaps Sharing User Interface`

