<script>
  import posthog from 'posthog-js'
  import { onMount } from 'svelte'
  import { setUser } from '@sentry/svelte'

  import { page } from '$app/stores'
  import { goto } from '$app/navigation'

  import * as m from '$translations/messages'
  import Container from '$lib/site/Container.svelte'
  import { variables } from '$lib/variables.js'

  import { signIn } from '@auth/sveltekit/client'
  import Button from '$lib/components/Button.svelte'
  import { getTitle } from '$lib/components/title'

  const triggerSignup = () => signIn('auth0', { callbackUrl: '/files' }, { screen_hint: 'signup' })
  let isUwu = false

  onMount(() => {
    isUwu = $page.url.searchParams.get('uwu') !== null
    const isPwa = $page.url.searchParams.get('source') === 'pwa'
    const isLoggedOut = $page.url.searchParams.get('logged-out') === 'true'
    if (isLoggedOut) {
      posthog.reset()
      setUser(null)
      goto('/', { replaceState: true })
    }
    if (isPwa) {
      goto('/login?source=pwa', { replaceState: true })
    }
  })
</script>

<svelte:head>
  <title>{getTitle(variables.appName, m.site_index_heading())}</title>
  <meta name="description" content={m.site_index_tagline()} />
</svelte:head>

<Container nav />
<div class="hero-bg">
  <Container>
    <div class="hero section center">
      {#if isUwu}
        <img class="uwu" src="/logo/wordmark-uwu.webp" alt="Turbomaps UwU Logo" />
      {:else}
        <h1>{m.site_index_heading()}</h1>
      {/if}
      <p class="description">{m.site_index_tagline()}</p>
      <Button size="hero" on:click={triggerSignup}>{m.site_index_callToActionShort()}</Button>
      <p class="beta">{m.site_index_pricingTagline()}</p>
      <img class="ui" src="/site/home-ui.webp" alt={m.site_index_image()} fetchpriority="high" />
    </div>
  </Container>
</div>
<div id="features">
  <Container padding>
    <div class="center">
      <h2>{m.site_index_drawHeading()}</h2>
      <p class="description">{m.site_index_drawDescription()}</p>
    </div>
    <div class="section flex">
      <div>
        <h3>{m.site_index_drawControlsHeading()}</h3>
        <p>{m.site_index_drawControlsDescription()}</p>
        <h3>{m.site_index_drawEditingHeading()}</h3>
        <p>{m.site_index_drawEditingDescription()}</p>
        <h3>{m.site_index_drawImportHeading()}</h3>
        <p>{m.site_index_drawImportDescription()}</p>
      </div>
      <div>
        <img src="/site/home-draw.webp" alt={m.site_index_drawImage()} />
      </div>
    </div>
  </Container>
</div>
<div class="design-bg">
  <Container padding>
    <div class="center">
      <h2>{m.site_index_designHeading()}</h2>
      <p class="description">{m.site_index_designDescription()}</p>
    </div>
    <div class="section flex">
      <div>
        <img src="/site/home-design-strokes.webp" alt={m.site_index_designStrokesImage()} />
        <h3>{m.site_index_designStrokesHeading()}</h3>
        <p>{m.site_index_designStrokesDescription()}</p>
      </div>
      <div>
        <img src="/site/home-design-text.webp" alt={m.site_index_designTextImage()} />
        <h3>{m.site_index_designTextHeading()}</h3>
        <p>{m.site_index_designTextDescription()}</p>
      </div>
      <div>
        <img src="/site/home-design-basemap.webp" alt={m.site_index_designBasemapImage()} />
        <h3>{m.site_index_designBasemapHeading()}</h3>
        <p>{m.site_index_designBasemapDescription()}</p>
      </div>
    </div>
  </Container>
</div>
<div class="share-bg">
  <Container padding>
    <h2>{m.site_index_shareHeading()}</h2>
    <div class="section flex">
      <div>
        <p>{m.site_index_shareDescription()}</p>
        <ul>
          <li>
            <strong>{m.site_index_shareLinkHeading()}</strong><br />
            {m.site_index_shareLinkDescription()}
          </li>
          <li>
            <strong>{m.site_index_shareExportHeading()}</strong><br />
            {m.site_index_shareExportDescription()}
          </li>
          <li>
            <strong>{m.site_index_shareCloudHeading()}</strong><br />
            {m.site_index_shareCloudDescription()}
          </li>
        </ul>
      </div>
      <div>
        <img src="/site/home-share.webp" alt={m.site_index_shareImage()} />
      </div>
    </div>
  </Container>
</div>
<div class="cta-bg">
  <Container footer padding>
    <div class="cta flex">
      <div>
        <h2>{m.site_index_callToAction()}</h2>
        <p>{m.site_index_heading()}</p>
      </div>
      <div class="center">
        <Button size="hero" on:click={triggerSignup}>{m.site_header_signup()}</Button>
        <p class="beta">{m.site_index_pricingTagline()}</p>
      </div>
    </div>
  </Container>
</div>

<style lang="scss">
  // general styles, reusable classes
  h1 {
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--font-serif);
    max-width: 400px;
    margin: 1.5rem auto;
    letter-spacing: -0.25px;
    line-height: 1.3;
  }
  h2 {
    font-family: var(--font-serif);
    font-size: 1.5rem;
    margin: 0.5em 0;
  }
  h3 {
    font-size: inherit;
    margin: 1em 0 0.25em;
  }
  p {
    margin: 0.25em 0 1em;
    color: var(--site-text-color);
  }
  ul {
    padding-left: 1rem;
    padding-bottom: 1rem;
    color: var(--site-text-color);
  }
  li {
    margin-bottom: 1em;
  }
  img {
    border-radius: 5px;
    width: 100%;
    max-width: 510px;
    height: auto;
    margin: 0 auto;
    display: block;
    box-shadow:
      0px 1px 0.5rem rgba(0, 0, 0, 0.1),
      0px 0px 0px 0.25px rgba(0, 0, 0, 0.2);
  }
  strong {
    font-weight: 600;
    color: #fff;
  }
  @media (min-width: 350px) {
    h1 {
      font-size: 2.5rem;
      letter-spacing: -0.5px;
    }
    h2 {
      font-size: 2rem;
    }
  }
  @media (min-width: 600px) {
    h1 {
      font-size: 3.5rem;
      max-width: 500px;
    }
    h2 {
      font-size: 2.25rem;
    }
  }

  // slightly reusable
  .section {
    line-height: 1.4;

    @media (min-width: 500px) {
      padding: 0.5rem 0;
      font-size: 1rem;
    }
    @media (min-width: 800px) {
      padding: 1.5rem 0;
    }
  }
  .center {
    text-align: center;
  }
  .description {
    margin: 1rem auto 2rem;
    font-size: 1rem;
    padding: 0 0.75rem;
    line-height: 1.4;

    @media (min-width: 500px) {
      font-size: 1.125rem;
      letter-spacing: -0.1px;
      max-width: 560px;
    }
  }
  .beta {
    margin: 1em 0 0;
    font-size: 13px;
    color: var(--tertiary-site-text-color);

    @media (min-width: 500px) {
      font-size: 15px;
    }
  }
  .flex {
    > div {
      flex: 1;

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    @media (min-width: 650px) {
      display: flex;
      gap: 1.5rem;
      align-items: center;

      > div:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @media (min-width: 800px) {
      gap: 2.5rem;
    }
    @media (min-width: 1200px) {
      gap: 3.875rem;
    }
  }

  // specific styles
  .hero-bg {
    background-color: rgba(250, 250, 249, 1);
    background-image: linear-gradient(rgba(250, 250, 249, 0.25), rgba(250, 250, 249, 1) 60%),
      url(/site/hero.webp);
    background-size: 100%, 300%;
    background-repeat: no-repeat;
    background-position:
      0,
      50% 0%;
    border-bottom: 1px solid #eee;

    @media (min-width: 600px) {
      background-size: 100%, 200%;
    }
    @media (min-width: 1600px) {
      background-size: 100%, 100%;
    }
  }
  .hero.section {
    padding: 2rem 0 0;
    overflow: hidden;

    .description {
      max-width: 340px;
    }

    img.uwu {
      box-shadow: none;
      padding: 0 0.25rem 0.5rem;
      box-sizing: border-box;
    }

    img.ui {
      margin: 3rem 0 -2rem;
      width: calc(100% - 1rem);
      max-width: 900px;
      border-radius: 3px;
      box-shadow:
        0px 1px 0.5rem rgba(0, 0, 0, 0.075),
        0px 0px 0px 0.25px rgba(0, 0, 0, 0.2);
      display: inline-block;
      aspect-ratio: 9 / 5;
    }

    @media (min-width: 600px) {
      padding: 3rem 0 0;

      .description {
        max-width: 375px;
      }

      img.ui {
        box-shadow:
          0px 1px 25px rgba(0, 0, 0, 0.15),
          0px 0px 0px 0.25px rgba(0, 0, 0, 0.2);
        width: calc(100% - 3rem);
        margin: 4rem 1.5rem -6rem;
        border-radius: 6px;
      }
    }
  }
  .design-bg {
    background: #fafaf9;
    border-top: 1px solid #eee;
  }
  .share-bg {
    background: #222;
    h2 {
      color: #fff;
    }
    p,
    ul {
      color: #eee;
    }
  }
  .cta-bg {
    background-color: rgba(250, 250, 249, 1);
    background-image: linear-gradient(rgba(250, 250, 249, 0.25), rgba(250, 250, 249, 1) 60%),
      url(/site/hero.webp);
    background-size: cover;
  }
  .cta {
    max-width: 500px;
    margin: 1rem auto;
    align-items: center;
    gap: 1rem;
    text-align: center;

    h2 {
      font-family: inherit;
      line-height: 1.25;
      font-size: 1.75rem;
    }

    @media (min-width: 500px) {
      text-align: left;
    }
  }

  #features {
    scroll-margin-top: 103px;

    @media (min-width: 700px) {
      scroll-margin-top: 60px;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    :global(html) {
      scroll-behavior: smooth;
    }
  }
</style>
